<template>

  <div class="popup-game-redirect">
    <div class="center-block">

      <div @click="GamePopup()" class="clos-btn">
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="28.2844" y="25.4561" width="4" height="36" rx="2" transform="rotate(135 28.2844 25.4561)"
                fill="#35786E"/>
          <rect x="2.82837" y="28.2843" width="4" height="36" rx="2" transform="rotate(-135 2.82837 28.2843)"
                fill="#35786E"/>
        </svg>
      </div>

      <a href="https://rebus.teva.ua/" target="_blank" class="history btn hover_type11"><p>Ребус Тева</p></a>

<!--      <span @click="GamePopup()"><router-link to="/games"-->
<!--                                              class=" game btn hover_type11"><p>Історії Тева</p></router-link></span>-->

      <span @click="GamePopup()"><router-link to="/amazing_medicine_game/introduction" class=" game btn-big hover_type11"><p>Нестандартна медицина в масовій культурі</p></router-link></span>

    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "popup-game-redirect",
  methods: {
    ...mapActions([
      'GamePopup'
    ]),
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.popup-game-redirect {

  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(53, 120, 110, 0.7);
  z-index: 200;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .center-block {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 70px 60px 50px 60px;
    max-width: 640px;
    width: 100%;

    .clos-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      svg {
        display: block;
      }
    }


    .btn {
      width: 195px;
      height: 60px;

      margin: 0 30px;
      background: $teal;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;


      p {
        position: relative;
        z-index: 2;

        font-family: $B;
        font-size: 24px;
        color: white;
      }
    }

    .btn-big {
      order: 2;
      height: 60px;
      width: 100%;
      padding: 0 20px;
      margin: 20px auto;

      background: $teal;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;


      p {
        position: relative;
        z-index: 2;
        text-align: center;

        font-family: $B;
        font-size: 20px;
        color: white;

      }
    }
  }
}

@media screen and (max-width: 700px) {
  .popup-game-redirect {

    .center-block {
      flex-direction: column;
      padding: 66px 48px 38px 48px;

      .clos-btn {
        right: 10px;
        top: 10px;
      }

      .btn {
        margin: 0;

        &.history {
          margin-bottom: 20px;
        }

        width: 190px;
        height: 48px;
      }

      .btn-big {

        &.history {
          margin-bottom: 20px;
        }

        width: 190px;
        padding: 10px;
        height: auto;
      }
    }
  }

}
</style>